import { useEffect, useState, useLayoutEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import Toggle from 'components/Toggle';
import TablePagination from 'components/TablePagination';
import NroTransporteCellRenderer from 'components/NroTransporteCellRenderer';
import EstadoEntregaCellRenderer from 'components/EstadoEntregaCellRenderer';
import EstadoTransporteCellRenderer from 'components/EstadoTransporteCellRenderer';
import RemitosCellRenderer from 'components/RemitosCellRenderer';
import FechaEstDespachoCellRenderer from 'components/FechaEstDespachoCellRenderer';
import Page from 'components/Page';
import Loading from 'components/Loading';
import FilterGroup from 'components/FilterGroup';
import useQuery from 'hooks/useQuery';
import useAxios from 'hooks/useAxios';
import useLocalStorage from 'hooks/useLocalStorage';
import { useAuth0 } from '@auth0/auth0-react';
import utils from 'utils';

const defaultColumns = [
  {
    field: 'toller_codigo',
    headerName: 'Código Almacén',
  },
  {
    field: 'toller_nombre',
    headerName: 'Descripción de Almacén',
  },
  {
    field: 'transporte_codigo',
    headerName: 'Nro. Transporte',
    type: 'numericColumn',
    cellRenderer: 'nroTransporteCellRenderer',
  },
  {
    field: 'transporte_estado',
    headerName: 'Estado Transporte',
    cellRenderer: 'estadoTransporteCellRenderer',
    center: true,
  },
  {
    field: 'notificacion_despacho',
    headerName: 'Notificación despacho',
    center: true,
    valueFormatter: ({ value }) =>
      value ? dayjs(value).format('DD/MM/YYYY HH:mm') : '',
  },
  {
    field: 'notificacion_proximidad',
    headerName: 'Notificación proximidad',
    center: true,
    valueFormatter: ({ value }) =>
      value ? dayjs(value).format('DD/MM/YYYY HH:mm') : '',
  },
  {
    field: 'notificacion_demora',
    headerName: 'Notificación demora',
    center: true,
    valueFormatter: ({ value }) =>
      value ? dayjs(value).format('DD/MM/YYYY HH:mm') : '',
  },
  {
    field: 'entrega_numero_pedido',
    headerName: 'Nro. Pedido',
    type: 'numericColumn',
  },
  {
    field: 'entrega_codigo',
    headerName: 'Nro. Entrega',
    type: 'numericColumn',
  },
  {
    field: 'entrega_estado',
    headerName: 'Estado Entrega',
    cellRenderer: 'estadoEntregaCellRenderer',
    center: true,
  },
  {
    field: 'entrega_tiene_remitos',
    headerName: 'Remitos',
    cellRenderer: 'remitosCellRenderer',
    center: true,
  },
  {
    field: 'cliente_codigo',
    headerName: 'Identificación Cliente',
    type: 'numericColumn',
  },
  {
    field: 'cliente_nombre',
    headerName: 'Descripción del Cliente',
  },
  {
    field: 'destinatario_codigo',
    headerName: 'Identificación Destinatario',
    type: 'numericColumn',
  },
  {
    field: 'destinatario_nombre',
    headerName: 'Descripción Destinatario',
  },
  {
    field: 'distribuidor_nombre',
    headerName: 'Descripción Distribuidor',
  },
  {
    field: 'material_codigo',
    headerName: 'Código del Material',
  },
  {
    field: 'material_descripcion',
    headerName: 'Descripción del material',
  },
  {
    field: 'material_cantidad',
    headerName: 'Cantidad de bolsas',
    type: 'numericColumn',
  },
  {
    field: 'entrega_despacho_estimado_el',
    headerName: 'Fecha est. despacho',
    cellRenderer: 'fechaEstDespachoCellRenderer',
    center: true,
  },
  {
    field: 'entrega_contabilizado_el',
    headerName: 'Fecha de despacho',
    center: true,
    valueFormatter: ({ value }) =>
      value ? dayjs(value).format('DD/MM/YYYY') : '',
  },
  {
    field: 'destinatario_localidad_nombre',
    headerName: 'Localidad',
  },
  {
    field: 'material_lote',
    headerName: 'Lote',
  },
  {
    field: 'entrega_numero_remito',
    headerName: 'Numero de remito',
    type: 'numericColumn',
  },
  {
    field: 'transportista_empresa',
    headerName: 'Empresa',
  },
  {
    field: 'transportista_nombre',
    headerName: 'Nombre transportista',
  },
  {
    field: 'transportista_dni',
    headerName: 'DNI Transportista',
    type: 'numericColumn',
  },
  {
    field: 'transportista_telefono',
    headerName: 'Teléfono Transportista',
    type: 'numericColumn',
  },
  {
    field: 'transportista_patente',
    headerName: 'Patente',
  },
];

const defaultFilters = {
  estados: {
    title: 'Estado transporte',
    options: [],
  },
  marcas: {
    title: 'Marca',
    options: [],
  },
  entregas: {
    title: 'Entrega',
    options: [],
  },
  pedidos: {
    title: 'Pedido',
    options: [],
  },
  materiales: {
    title: 'Material',
    options: [],
  },
  clientes: {
    title: 'Cliente',
    options: [],
  },
};

const Dashboard = ({ entregas }) => {
  const values = useMemo(() => {
    const entregasDespachadas = entregas.filter(
      (e) => e.transporte_estado !== 'planificado'
    );

    const entregasPlanificadas = entregas.filter(
      (e) =>  e.transporte_estado === 'planificado'
    );

    return {
      transportes: new Set(entregas.map((e) => e.transporte_id)).size,
      bolsasDespachadas: entregasDespachadas.reduce((prev, curr) => prev + curr.material_cantidad, 0),
      bolsasPlanificadas: entregasPlanificadas.reduce((prev, curr) => prev + curr.material_cantidad, 0),
      entregasDespachadas: new Set(entregasDespachadas.map((e) => e.entrega_id)).size,
      entregasPlanificadas: new Set(entregasPlanificadas.map((e) => e.entrega_id)).size,
    };
  }, [entregas]);

  return (
    <div className="d-flex gap-3 w-100">
      <div className="d-flex gap-3 justify-content-center align-items-center bg-white rounded p-3 w-100">
        <div className="d-flex gap-1">
          <strong>Cant. Transportes:</strong>
          {utils.formatNumber(values.transportes)}
        </div>
      </div>
      <div className="d-flex gap-3 justify-content-center align-items-center bg-white rounded p-3 w-100">
        <div className="d-flex gap-1">
          <strong>Bolsas despachadas:</strong>
          {utils.formatNumber(values.bolsasDespachadas)}
        </div>
        <div className="d-flex gap-1">
          <strong>Bolsas planificadas:</strong>
          {utils.formatNumber(values.bolsasPlanificadas)}
        </div>
      </div>
      <div className="d-flex gap-3 justify-content-center align-items-center bg-white rounded p-3 w-100">
        <div className="d-flex gap-1">
          <strong>Entregas despachadas:</strong>
          {utils.formatNumber(values.entregasDespachadas)}
        </div>
        <div className="d-flex gap-1">
          <strong>Entregas planificadas:</strong>
          {utils.formatNumber(values.entregasPlanificadas)}
        </div>
      </div>
    </div>
  )
};

export default function Entregas() {
  const axios = useAxios();
  const query = useQuery();
  const { user } = useAuth0();
  const [selectedCache, setSelectedCache] = useLocalStorage(`${user.sub}.filtros`, {});
  const [entregas, setEntregas] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [filters, setFilters] = useState({});
  const [selectedOptions, setSelectedOptions] = useState(selectedCache);

  const fetchData = async () => {
    setIsLoading(true);

    try {
      const response = await axios.get('/logistica/entregas', {
        params: selectedOptions,
      });
      setEntregas(response.data);
    } catch (error) {
      console.error(error);
    }

    setIsLoading(false);
  };

  const fetchFilters = async () => {
    setIsLoading(true);

    try {
      const response = await axios.get('/logistica/transportes/filtros');

      Object.keys(response.data ?? {}).forEach((key) => {
        defaultFilters[key].options = response.data[key];
      });

      setFilters({ ...defaultFilters });
    } catch (error) {
      console.error(error);
    }

    setIsLoading(false);
  };

  const handleCleanFilters = () => {
    setSelectedOptions({});
  };

  const handleApplyFilter = (key, selected) => {
    setSelectedOptions({
      ...selectedOptions,
      [key]: selected,
    });
  };

  useEffect(() => {
    setSelectedCache({...selectedOptions});
    fetchData();
  }, [selectedOptions]);

  useEffect(() => {
    const codigo = query.get('codigo');

    if (codigo) {
      handleApplyFilter('entregas', [codigo]);
    }

    fetchFilters();
  }, []);

  return (
    <Page>
      {isLoading && <Loading />}

      <Page.Content>
        <Page.Header title="Listado de entregas">
          <div className="d-flex align-items-center">
            <span className="me-2">Mostrar Historico</span>
            <Toggle
              value={selectedOptions.historico || false}
              onChange={(value) => handleApplyFilter('historico', value)}
            />
          </div>
        </Page.Header>

        <div className="mb-2">
          <Link
            to={`/?${new URLSearchParams(selectedOptions).toString()}`}
            className="btn btn-sm btn-primary"
          >
            Ver mapa
          </Link>
        </div>

        <div className="flex-grow-1">
          <TablePagination
            frameworkComponents={{
              nroTransporteCellRenderer: NroTransporteCellRenderer,
              estadoTransporteCellRenderer: EstadoTransporteCellRenderer,
              estadoEntregaCellRenderer: EstadoEntregaCellRenderer,
              remitosCellRenderer: RemitosCellRenderer,
              fechaEstDespachoCellRenderer: FechaEstDespachoCellRenderer,
            }}
            data={entregas}
            columns={defaultColumns}
            HeaderComponent={() => (
              <FilterGroup
                filters={filters}
                selectedOptions={selectedOptions}
                onClean={handleCleanFilters}
                onApply={handleApplyFilter}
              />
            )}
            onCellValueChanged={() => fetchData()}
            BeforeComponent={() => <Dashboard entregas={entregas} />}
            pagination={false}
          />
        </div>
      </Page.Content>
    </Page>
  );
}
